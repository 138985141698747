import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Component} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {postData} from "./helpers/postData";
import ImageService from "./pages/ImageService";
import PageEditor from "./pages/PageEditor";
import LoginPage from "./pages/LoginPage";

class App extends Component {
    state = {
        message: "Getting data from server..",
        navbarExtended: false,
        user: null,
        fetchedUser: false
    }

    componentDidMount() {
        this.fetchSession();
    }

    setUser(user) {
        this.setState({user, fetchedUser: true})
    }

    async fetchSession() {
        let request = await postData('/getsession', {});
        try {
            let json = await request.json();
            console.log("app fetch session", json);
            if (json)
                this.setUser(json)
            else
                this.setState({fetchedUser: true});
        } catch (ex) {
            this.setState({fetchedUser: true});
        }
    }

    async logoutUser() {
        await postData('/logout', {})
        this.setState({user: null, navbarExtended: false})
    }

    clickedNavbarItem() {
        this.setState({navbarExtended: false});
    }

    toggleNavbar() {
        this.setState({navbarExtended: !this.state.navbarExtended});
    }

    buildTopNavBar() {
        const {navbarExtended, user} = this.state;
        console.log("user state", user)

        return (
            <Navbar expand="lg" expanded={navbarExtended} sticky="top">
                <Container>
                    <Navbar.Brand as={Link} to="/" onClick={() => this.clickedNavbarItem()}>MangaEditor</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.toggleNavbar()}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" onClick={() => this.clickedNavbarItem()} href="#">Home</Nav.Link>
                            <Nav.Link as={Link} to="/imageservice" onClick={() => this.clickedNavbarItem()}
                                      href="#">Image Service</Nav.Link>
                            <Nav.Link as={Link} to="/pageeditor" onClick={() => this.clickedNavbarItem()}
                                      href="#">Page Editor</Nav.Link>
                        </Nav>


                        <Nav>
                            {
                                user ? (
                                    <NavDropdown title={user.username} id="basic-nav-dropdown">
                                        <NavDropdown.Item onClick={() => this.logoutUser()}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                ) : (
                                    <Nav.Link as={Link} to="/login" href="#"
                                              onClick={() => this.clickedNavbarItem()}>{user ? user.username : "User"}</Nav.Link>
                                )
                            }
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }

    render() {
        const {fetchedUser} = this.state;

        return (
            <div style={{width: "100%", height: "100%"}}>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
                <meta name="viewport"
                      content="viewport-fit=cover, user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1"/>
                {
                    fetchedUser ? (
                        <BrowserRouter>
                            {
                                this.buildTopNavBar()
                            }

                            <Routes>
                                <Route path="/"
                                       element={<div style={{width: "100%", height: "100%"}}><p>Home Page</p></div>}/>

                                <Route path="/imageservice"
                                       element={<ImageService getUser={() => this.state.user}/>}/>
                                <Route path="/pageeditor"
                                       element={<PageEditor getUser={() => this.state.user}/>}/>
                                <Route path="/login"
                                       element={<LoginPage getUser={() => this.state.user} setUser={(user) => this.setUser(user)}/>}/>

                                <Route path="*" element={<div><p>page not found</p></div>}/>
                            </Routes>
                        </BrowserRouter>
                    ) : (
                        <div>
                            Loading...
                        </div>
                    )
                }

            </div>
        );
    }
}

export default App;
