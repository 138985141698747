import {Component} from "react";
import {Button, Form} from "react-bootstrap";
import {postData} from "../helpers/postData";

class LoginPage extends Component {
    state = {
        username: "",
        password: "",
        showPassword: false
    }

    fetchUserSubmit(e) {
        e.preventDefault();
        this.fetchUser();
    }

    async fetchUser() {
        const {username, password} = this.state;
        console.log("state", this.state)
        const request = await postData('/login', {username, password});
        // var request = await postData('/login', { username, password });
        const json = await request.json();
        if (json.success) {
            this.props.setUser(json.success ? json.user : null);
        } else {
            alert(json.message);
        }
        console.log("loginform fetch json", json);
    }

    render() {
        const {showPassword} = this.state;
        const user = this.props.getUser();

        return (
            <div>
                <p> login page !</p>
                {
                    !user ? (
                        <Form onSubmit={(e) => this.fetchUserSubmit(e)}>
                            <Form.Group className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" id="username" placeholder="username"
                                              onChange={(e) => this.setState({username: e.target.value})}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <div style={{display: "flex"}}>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        style={{paddingRight: "5px"}}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                    />
                                    <Button
                                        variant="light"
                                        className="password-toggle-button"
                                        onClick={() => this.setState({showPassword: !showPassword})}
                                    >{showPassword ? "H" : "S"}</Button>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Button onClick={() => this.fetchUser()}>Login</Button>
                            </Form.Group>
                        </Form>
                    ) : (<p>you are logged in!</p>)
                }

            </div>
        )
    }
}

export default LoginPage;