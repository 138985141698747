import {Component} from "react";
import AuthenticatedPageHOC from "../hoc/AuthenticatedPageHOC";
import {Carousel, Col, Container, Image, Row} from "react-bootstrap";
import {postData} from "../helpers/postData";

class ImageService extends Component {
    state = {
        imageNames: [],
        imagePage: 0
    }

    async componentDidMount() {
        let imageNameReq = await postData("/getimagenames", {});
        let imageNames = await imageNameReq.json();
        this.setState({imageNames});
    }

    buildImageCarousel() {
        const { imageNames, imagePage } = this.state;
        let section = imageNames.slice(imagePage * 9, imagePage * 9 + 9);
        console.log("section", section)

        // Fill the remaining slots with "imagenotfound.png" if section length is less than 9
        while (section.length < 9) {
            section.push("imagenotfound.png");
        }

        const rows = [];

        for (let i = 0; i < section.length; i += 3) {
            const columns = section.slice(i, i + 3).map(imageName => (
                <Col key={imageName + i} xs={2} md={3}>
                    <Image
                        style={{ width: "200px", height: "200px" }}
                        // src={`http://127.0.0.1:8000/images/${imageName}`}
                        src={`https://cmsapi.stepmanga.com/images/${imageName}`}
                        alt={imageName}
                    />
                </Col>
            ));
            rows.push(<Row key={i}>{columns}</Row>);
        }

        return (
            <Container>
                {rows}
            </Container>
        );
    }

    render() {
        return (
            <div>
                <p>image service page !</p>
                {
                    this.buildImageCarousel()
                }
            </div>
        )
    }
}

export default AuthenticatedPageHOC(ImageService);