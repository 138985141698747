
// const FRONTEND_ORIGIN = "https://127.0.0.1:3000"; //this is us
// const API_ENDPOINT = `http://127.0.0.1:8000`;

//production
const FRONTEND_ORIGIN = "https://cms.stepmanga.com"; //this is us
const API_ENDPOINT = `https://cmsapi.stepmanga.com`;

export const postData = (url, json) => {
    let formedUrl = `${API_ENDPOINT}${url}`;
    // console.log("what does url look like?", formedUrl, json)
    return fetch(formedUrl, {
        mode: 'cors',
        // mode: 'no-cors',
        credentials: 'include',
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Origin': FRONTEND_ORIGIN
        },
        body: JSON.stringify(json)
    });
};

export const postFormData = (url, formData) => {
    var formedUrl = `${API_ENDPOINT}${url}`;
    // console.log("what does url look like?", formedUrl)
    return fetch(formedUrl, {
        mode: 'cors',
        credentials: 'include',
        method: "POST",
        headers: {
            'Origin': FRONTEND_ORIGIN
        },
        body: formData
    });
};

export const getData = (url) => {
    var formedUrl = `${API_ENDPOINT}${url}`;
    // console.log("what does url look like?", formedUrl)
    return fetch(formedUrl, {
        mode: 'cors',
        credentials: 'include',
        method: "GET",
        headers: {
            // 'Origin': FRONTEND_ORIGIN
        }
    });
};