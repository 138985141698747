import {Component} from "react";
import AuthenticatedPageHOC from "../hoc/AuthenticatedPageHOC";
import {DropdownButton, Dropdown, Image} from "react-bootstrap";
import {postData, postFormData} from "../helpers/postData";

class PageEditor extends Component {
    state = {
        totalPages: 0,
        missingPages: [],
        lastSelectedPage: -1,
        currentPageImage: null,

        localFile: null,
        localFilePath: null,
    }

    async componentDidMount() {
        let pagesReq = await postData("/getpages", {});
        const {totalPages, missingPages} = await pagesReq.json();
        this.setState({totalPages, missingPages});
    }

    async pageSelected(pageNum) {
        const {missingPages} = this.state;
        console.log("page selected", pageNum);
        console.log("is missing", missingPages.includes(pageNum))

        let pageImageReq = await postData("/getpageimage", {pageNum});
        const {image} = await pageImageReq.json();

        this.setState({lastSelectedPage: pageNum, currentPageImage: image, localFile: null, localFilePath: null})
    }

    handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            this.setState({localFile: file, localFilePath: URL.createObjectURL(file)});
        }
    }

    async handleUpload() {

        const {localFile, lastSelectedPage, missingPages} = this.state;

        if (!localFile) return;

        const formData = new FormData();
        formData.append('file', localFile);
        formData.append('page', lastSelectedPage);

        let uploadResponse = await postFormData('/uploadimage', formData);
        let json = await uploadResponse.json();

        if (json.success) {
            this.setState({
                missingPages: missingPages.filter(mp => mp !== lastSelectedPage),
                currentPageImage: json.image
            });
        }
        console.log(json);

    }

    addPage() {
        const {totalPages, missingPages} = this.state;
        missingPages.push(totalPages + 1)
        this.setState({
            totalPages: totalPages + 1,
            missingPages,
            lastSelectedPage: totalPages + 1,
            currentPageImage: "imagenotfound.png"
        })
    }

    deleteCurrentPage() {
        const {totalPages, missingPages, lastSelectedPage} = this.state;
        console.log("totalPages", totalPages, "lastSelectedPage", lastSelectedPage);

        if (totalPages === lastSelectedPage) {
            this.setState({
                missingPages: missingPages.includes(lastSelectedPage) ? missingPages.filter(i => i !== lastSelectedPage) : missingPages,
                lastSelectedPage: -1,
                totalPages: totalPages - 1,
                currentPageImage: "imagenotfound.png"
            });
            postData('/deletepage', {pageNum: lastSelectedPage});
        } else {
            if (!missingPages.includes(lastSelectedPage)) {
                missingPages.push(lastSelectedPage);
                this.setState({
                    missingPages,
                    currentPageImage: "imagenotfound.png"
                });
                postData('/deletepage', {pageNum: lastSelectedPage});
            }
        }
    }

    render() {
        const {totalPages, missingPages, lastSelectedPage, currentPageImage, localFile, localFilePath} = this.state;
        const pagesArray = Array.from({length: totalPages}, (_, i) => i + 1); // Generate array of pages

        return (
            <div>
                <p>Page Editor Page!</p>
                <p>Total Pages: {totalPages}</p>
                <p>Missing Pages: {missingPages.length}</p>

                <DropdownButton id="dropdown-basic-button"
                                title={lastSelectedPage === -1 ? "Choose a page to edit" : `Page ${lastSelectedPage} ${missingPages.includes(lastSelectedPage) ? '(missing)' : ''}`}>
                    <Dropdown.Item key="addnewpage" onClick={(e) => this.addPage()}>
                        Add New Page
                    </Dropdown.Item>
                    {pagesArray.map((page, index) => (
                        <Dropdown.Item key={index} onClick={(e) => this.pageSelected(page)}>
                            Page {page} {missingPages.includes(page) ? '(missing)' : ''}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                {
                    lastSelectedPage !== -1 ? (
                        <div>
                            <Image
                                style={{width: "200px", height: "200px"}}
                                // src={`http://127.0.0.1:8000/images/${currentPageImage}`}
                                src={`https://cmsapi.stepmanga.com/images/${currentPageImage}`}
                                alt={currentPageImage}
                            />
                            <input type="file" onChange={(e) => this.handleFileChange(e)}/>
                            {localFilePath &&
                                <img src={localFilePath} alt="Selected"
                                     style={{maxWidth: '200px', marginTop: '10px'}}/>}
                            <button onClick={() => this.handleUpload()} disabled={!localFile}>
                                Upload & Update Page
                            </button>
                            <button onClick={() => this.deleteCurrentPage()}>Delete Page On Server</button>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

export default AuthenticatedPageHOC(PageEditor);