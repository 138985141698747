import React, {Component} from 'react';

const AuthenticatedPageHOC = WrappedComponent => {
    return class extends Component {
        renderNoAuthentication() {
            return (
                <div>
                    <p>
                        Log in is required to access this page.
                    </p>
                </div>
            )
        }

        render() {
            try {
                if (this.props.getUser())
                    return <WrappedComponent {...this.props} />;
                else
                    return this.renderNoAuthentication();
            } catch (e) {
                return this.renderNoAuthentication();
            }
        }
    };
};

export default AuthenticatedPageHOC;